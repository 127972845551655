.Navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #eee;
  .NavItem {
    cursor: pointer;
    padding: 15px 10px;
  }
  .Menu {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .Navbar {
    position:fixed;
    z-index: 1000;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 20vh;
    .NavItem {
      margin-bottom: 10px;
      width: 80vw;
      text-align: center;
    }
    .Menu {
      display: block;
      position:absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 24px;
      padding: 28px 20px 20px 20px;
    }
  }
  .Navbar.Hidden {
    left: unset;
    position: fixed;
    right: 0;
    width: 70px;
    height: 60px;
    padding: 0;
    background-color: transparent;
    overflow: hidden;

    .NavItem {
      width: 0;
      height: 0;
      overflow: hidden;
      font-size: 0;
    }
  }
}