.Header {
  line-height: 120px;
  font-size: 42px;
  font-weight: bold;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .Header {
    font-size: 28px;
    line-height: 80px;
  }
}