.Support {
  width: 800px;
  margin: 0 auto;
  padding: 30px 0;
  position: relative;
  .Title {
    margin-bottom: 50px;
  }
  .Row {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    label {
      width: 50px;
      margin-bottom: 6px;
    }
    input, textarea {
      margin-bottom: 10px;
      border: 0;
      outline: none;
      box-sizing: border-box;
      border-bottom: 1px solid #aaa;
      font-size: 14px;
      padding: 5px 10px;
    }
    input:hover, textarea:hover,
    input:focus, textarea:focus {
      border: 1px solid black;
    }
    input {
      height: 30px;
    }
    textarea {
      resize: none;
      height: 150px;
    }
    button {
      border: 0;
      line-height: 50px;
      color: white;
      background-color: black;
      font-size: 18px;
      cursor: pointer;
    }
    button:hover {
      opacity: 0.5;
    }
    button.invalid {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  .Success {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      background-color: black;
      color: white;
      border-radius: 10px;
      width: 300px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  .Success.Hidden {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .Support {
    width: 80%;
  }
}