.Service {
  text-align: center;
  background-color: #eee;
  .AppInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Stores {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .Image {
    display: block;
    width: 100%;
    height: 500px;
    object-fit: contain;
  }
  .Image.main {
    width: 360px;
    height: auto;
  }
  .button {
    display: flex;
    width: 150px;
    height: auto;
    margin: 0 10px;
  }
  .Title {
    margin: 30px 0;
  }
  .Content {
    margin: 0 auto;
    font-size: 18px;
    width: 800px;
    margin-bottom: 30px;
  }
  .Sections {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Section {
    width: 100vw;
    padding: 20px 0;
    .SectionWrap {
      width: 800px;
      display: flex;
      margin: 0 auto;
    }
    .Image {
      width: 60%;
      margin: 0 20px;
      border-radius: 20px;
    }
    .Description {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #555;
      font-size: 24px;
      font-weight: bold;
      transition: all 500ms;
    }
  }
  .Section01 {
    background-color: white;
  }
  .Section03 {
    background-color: white;
  }
  .Mobile {
    display:none !important;
  }
}

@media only screen and (max-width: 800px) {
  .Service {
    .Image {
      height: 400px;
    }
    .button {
      height: auto;
    }
    .Content {
      width: 80vw;
    }
    .Sections {
      .Section {
        width: 100%;
        .SectionWrap {
          width: 80vw;
          flex-direction: column;
        }
        .Image {
          width: 70vw;
          height: auto;
          margin: 0 auto;
          margin-bottom: 20px;
        }
        .Description {
          width: 100%;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      .Section01, .Section03 {
        .SectionWrap {
          flex-direction: column-reverse;
        }
      }
    }
    .Mobile {
      display: block !important;
    }
    .PC {
      display: none !important;
    }
  }
}