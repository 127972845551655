.Map {
  background-color: #eee;
  .Wrap {
    width: 800px;
    margin: 0 auto;
    padding: 30px 0;
  }
  .Title {
    margin-bottom: 30px;
  }
  .Content {
    margin-bottom: 20px;
  }
  .MapHolder {
    width: 100%;
    height: 400px;
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .Map {
    width: 80%;
    padding: 0 10%;
    .Wrap {
      width: 100%;
    }
  }
}