.Company {
  background-color: #eee;
  text-align: center;
  padding-bottom: 100px;
  .Images {
    position: relative;
    height: 500px;
  }
  .ImageHolder {
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
    transition: opacity 2s;
  }
  .Image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ImageHolder.Hidden {
    opacity: 0;
  }
  .ImageDescription {
    position:absolute;
    width: 800px;
    height: 100%;
    left: calc(50vw - 400px);
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    
    h2 {
      font-size: 48px;
      margin-bottom: 20px;
      line-height: 1.2em;
    }
    p {
      font-size: 24px;
    }
  }
  .Filter {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
  }
  .Title {
    margin: 30px 0;
  }
  .Content {
    margin: 0 auto;
    font-size: 18px;
    width: 800px;
  }
  .Mobile {
    display:none;
  }
}

@media only screen and (max-width: 800px) {
  .Company {
    .Image {
      height: 400px;
    }
    .Content {
      width: 80vw;
      text-align: justify;
    }
    .Image {
      height: 500px;
    }
    .ImageDescription {
      width: 80vw;
      left: calc(50vw - 40vw);
      h2 {
        font-size: 32px;
        margin-bottom: 10px;
      }
      p {
        font-size: 18px;
      }
    }
    .Mobile {
      display: block;
    }
    .PC {
      display: none;
    }
  }
}